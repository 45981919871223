.profile{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #D9D9D9;
    background-color: #e7e7e7;
}
.section__profile{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px 40px 30px;

    background-color: #F3F4F7 ;

}
.profile__img-wrapper {
    display: flex;
    justify-content: center; /* Центрирование по горизонтали */
    align-items: center; /* Центрирование по вертикали */
    margin: 0 auto 20px auto; /* Отступ снизу */
    width: 150px;
    height: 150px;
    border: 4px solid #fff;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Обрезает изображение по границам круга */
}

.profile__img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Сохранение пропорций изображения */
}


.uploadProfile-img{
    margin: 0 0 15px 0;
    cursor: pointer;
}
.uploadProfile-img:hover{
    color: #1F800C;
}

.name{
    text-align: center;
    font-weight: 600;
    font-size: 18px;
}
.info-el{
    padding: 20px;
    background-color: #fff;
    margin-bottom: 10px;

    width: 100%;
    padding: 25px 30px;
    border-radius: 10px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.04);
    border: 2px solid #B9B9B9;
    border: 1px solid rgba(134, 134, 134, 0.4);
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.06);

}
.info-el-ref{
    margin-top: 10px;
    width: 100%;
    font-size: 16px;
    background-color: green;
    text-align: center;
    color: #fff;
    padding: 18px 0;
    border-radius: 15px;

}
.logout{
    margin: 20px 0 0 0;
    width: 100%;
    font-size: 16px;
    background-color: #000;
    text-align: center;
    color: #fff;
    padding: 18px 0;
    border-radius: 15px;

}
.logout:hover{
    background-color: #2e2e2e;
}


.verify{
    margin: 60px 0 0 0;
    width: 100%;
    font-size: 16px;
    background-color: green;
    text-align: center;
    color: #fff;
    padding: 18px 0;
    border-radius: 15px;

}
.verify:hover{
    background-color: #2e2e2e;
}


.area3{
    width: 100%;
    height: 100px;
    background-color: #e7e7e7;
    background-color: #F3F4F7 ;
}



@media only screen and (min-width: 768px) {
    .verify {
        width: 440px;
    }
    .logout{
        width: 440px;
        
    }
  }

@media only screen and (min-width: 1024px) {
    .verify{
        display: none;
    }
}




.settings-button {
    margin-top: 10px;
    width: 100%;
    font-size: 16px;
    text-align: center;
    color: #000;
    padding: 18px 0;
    border-radius: 15px;
    border: 1px solid #b4b4b4;
}

.settings-button:hover {
    background-color: #d8d8d8;

}